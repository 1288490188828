import { useEffect, useRef, useState } from "react";
import { IConnectionInfoExtended } from "../services/interfaces/ISignalRService";
import moment from "moment";
import { HubConnectionBuilder, LogLevel, HubConnection, HubConnectionState } from "@microsoft/signalr";

const runningStates: HubConnectionState[] = [HubConnectionState.Connected, HubConnectionState.Connecting, HubConnectionState.Reconnecting];

export type SignalRClientStatus = "init" | "connecting" | "reconnecting" | "connected" | "error";

export default function useSignalRClient(
    connectionFactory: () => Promise<IConnectionInfoExtended>,
    callbacks: { [methodName: string]: (...args: any) => void }
): [SignalRClientStatus, () => void] {

    const [status, setStatus] = useState<SignalRClientStatus>("init");
    const [resetCount, setResetCount] = useState<number>(0);

    const reset = (): void => {
        console.log(`in reset status is: ${status}`);
        if (status === "reconnecting" || status === "connected" || status === "error") {
            // note: can only reset if already connected, ignore otherwise
            setResetCount(resetCount + 1);
        }
    };

    const connectionFactoryRef = useRef(connectionFactory);
    connectionFactoryRef.current = connectionFactory;

    const callbacksRef = useRef(callbacks);
    callbacksRef.current = callbacks;

    useEffect(() => {
        let hub: HubConnection | null = null;
        (async () => {
            let connectionInfo = await connectionFactoryRef.current();

            hub = new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .withUrl(connectionInfo.url, {
                    accessTokenFactory: async (): Promise<string> => {
                        if (connectionInfo.accessTokenExpiry.isBefore(moment())) {
                            return connectionInfo.accessToken;
                        }
                        const connectionInfoRefreshed = await connectionFactoryRef.current();
                        if (connectionInfo.url !== connectionInfoRefreshed.url) {
                            // url has changed, talking to a different hub (need to reset incase anything else changed)
                            setResetCount(resetCount + 1);
                            return "";
                        } else {
                            return connectionInfoRefreshed.accessToken;
                        }
                    }
                })
                .build();

            hub.onreconnecting((err) => {
                setStatus("reconnecting");
                console.log("connection lost, attempting reconnect");
            });
            hub.onreconnected((connectionId) => {
                setStatus("connected");
                console.log(`reconnected: connectionId: ${connectionId}`);
            });
            hub.onclose((err) => {
                setStatus("error");
                console.log('connection closed, failed to reconnect automatically');
                console.error(err);
            });

            // register callback handlers
            const methodNames = Object.keys(callbacksRef.current);
            if (methodNames && methodNames.length > 0) {
                for (let i = 0, l = methodNames.length; i < l; i++) {
                    hub.on(methodNames[i], (...args: any) => callbacksRef.current[methodNames[i]](args));
                }
            }

            setStatus("connecting");
            try {
                await hub.start();
                setStatus("connected");
            } catch (err) {
                console.log("error connecting to hub");
                console.error(err);
                setStatus("error");
            }
        })();

        return () => {
            console.log('in cleanup');
            if (hub && runningStates.includes(hub.state)) {
                console.log('closing hub');
                hub.stop();
            }
        }
    }, [resetCount]);

    return [status, reset];
};
