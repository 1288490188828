import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { AuthState, UserInfo } from "./authReducer";

const authStateSelector = (state: RootState): AuthState => state.auth;

export const selectIsAuthenticated = createSelector<RootState, AuthState, boolean>(
    authStateSelector,
    (state) => state.authHeaderValue !== undefined,
);

export const selectAuthHeaderValue = createSelector<RootState, AuthState, string>(
    authStateSelector,
    (state) => state.authHeaderValue ? state.authHeaderValue : "",
);

export const selectUserInfo = createSelector<RootState, AuthState, UserInfo | undefined>(
    authStateSelector,
    (state) => state.userInfo,
);
