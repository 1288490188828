import React from "react";
import "./Avatar.scss";
import { UserStatuses } from "../services/interfaces/IDashboardService";

interface AvatarProps {
  userName?: string;
  initials?: string;
  imageUrl?: string;
  width?: number;
  height?: number;
  userStatus?: keyof typeof UserStatuses;
  notify?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  userName,
  initials,
  imageUrl,
  width = 50,
  height = 50,
  userStatus = "offline",
  notify = false
}) => {
  const avatarRingStyle = {
    width: width + "px",
    height: height + "px"
  };

  const avatarStyle = {
    width: width - 5 + "px",
    height: height - 5 + "px"
  };

  const imageWidth = width * 0.8;
  const imageHeight = width * 0.8;

  return (
    <div
      className={`avatar-ring ${userStatus} ${notify ? "notify" : ""}`}
      style={avatarRingStyle}
      title={`${userName} - ${UserStatuses[userStatus]}`}
      data-testid="avatar"
    >
      <div className="avatar" style={avatarStyle}>
        {imageUrl && imageUrl !== "" ? (
          <img
            alt="avatar"
            src={imageUrl}
            width={imageWidth}
            height={imageHeight}
          />
        ) : initials && initials !== "" ? (
          <h3 data-testid="avatar-initials">{initials}</h3>
        ) : (
              <span className="icon" data-testid="avatar-icon">
                <i className="fas fa-user"></i>
              </span>
            )}
      </div>
    </div>
  );
};

export default Avatar;
