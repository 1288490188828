import React, { useEffect, useState } from 'react';
import DashboardService from "../../services/DashboardService";
import { IReleaseInfo } from '../../services/interfaces/IDashboardService';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const controller = new DashboardService({ sendAsync: fetch.bind(window) });

const ReleasesTile = () => {
    const [releases, setReleases] = useState<IReleaseInfo[]>();

    useEffect(() => {
        async function getData() {
            const response = await controller.getReleases();
            setReleases(await response);
        }
        setInterval(() => {
            getData()
        },300000)
        getData();
    },[])    

    return(
    <div className="tile is-parent is-6">
        <article className="tile is-child">
            <p className="title"><b>Releases</b></p>
            <div className="content">
                {releases !== undefined ?      
                <>              
                    <ResponsiveContainer width={'99%'} height={400}>
                        <BarChart
                        width={600}
                        height={300}
                        data={releases}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                        <XAxis dataKey="name"/>
                        <YAxis label={{ value: 'No. Issues', position: 'insideLeft', textAnchor: 'middle', transform: 'rotate(-90, 35, 190)', fontSize: '20'}}/>
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="closed" fill="#9bbd1e" stackId="a" name="Closed" />
                        <Bar dataKey="open" fill="#de007e" stackId="a" name="Open"/>
                        </BarChart>
                    </ResponsiveContainer>
                    </>
                :
                    <div className="loading-msg">
                        <p><b className="loading-text">Loading...</b></p>
                        <progress className="progress is-small is-danger" max="100" />
                    </div>
                }
            </div> 
        </article>
    </div>
    )
}

export default ReleasesTile;