import { CALLBACK_PATH, SILENT_RENEW_PATH } from "./config";

type DisplayMode = "dashboard" | "flex" | "website";
export function getDisplayMode(): DisplayMode {
    const path = window.location.pathname.toLowerCase();
    return path.startsWith("/flex")
        ? "flex"
        : path.startsWith("/dashboard")
            ? "dashboard"
            : "website";
};

type CallbackMode = "none" | "background" | "foreground";
export function getCallbackMode(): CallbackMode {
    return window.location.pathname.startsWith(CALLBACK_PATH)
        ? "foreground"
        : window.location.pathname.startsWith(SILENT_RENEW_PATH)
            ? "background"
            : "none";
};

const key = "redirect:pathname";
export function storePath() {
    if (window.sessionStorage) {
        window.sessionStorage.setItem(key, window.location.pathname);
    }
};

export function setPathAfterLoginCallback() {
    let path: string | null = null;
    if (window.sessionStorage) {
        path = window.sessionStorage.getItem(key);
    }
    if (!path) {
        path = "/";
    }
    window.history.replaceState({}, window.document.title, path);
};
