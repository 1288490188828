import React from "react";
import "./SidebarLayout.scss";

interface SidebarLayoutProps {
  centerItems?: JSX.Element[];
  sideItem?: JSX.Element;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  sideItem,
  children
}) => {
  return (
    <div className="sidebar-layout">
      <div className="columns is-gapless">
        <div className="column">
          <div className="tile is-ancestor">{children}</div>
        </div>
        <div className="column side-column is-narrow">{sideItem}</div>
      </div>
    </div>
  );
};

export default SidebarLayout;
