import React, { useState, useEffect } from "react";
import { UserManager, User } from "oidc-client";
import Avatar from "../components/Avatar";
const md5 = require("md5");

export interface ProfileProps {
    userManager: UserManager;
}

const Profile: React.FC<ProfileProps> = (props) => {
    const [user, setUser] = useState<User | null>(null);
    const [img, setImg] = useState("");
    const [imgClicked, setImgClicked] = useState(false);

    useEffect(() => {
        async function getUser() {
            const currUser = await props.userManager.getUser();
            setUser(currUser);
            setImg(`${window.location.protocol}//www.gravatar.com/avatar/${md5(currUser?.profile.email)}`);
        }
        getUser();
    }, [props.userManager]);
    return (
        <div>
            {user !== undefined ? (
                <>
                    <div style={{ float: "left" }} onClick={() => setImgClicked(!imgClicked)}>
                        {user ? <Avatar userName={user.profile.name} userStatus={"available"} imageUrl={img} /> : <></>}
                    </div>
                    <div style={{ position: "absolute", zIndex: 1, right: "5px", marginTop: "2%" }}>
                        {imgClicked ? <button className={"button"}>Log out</button> : null}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Profile;
