import React from "react";
import { IUser } from "../services/interfaces/IDashboardService";
import Avatar from "./Avatar";

import "./UserActivityBar.scss";

interface UserActivityBarProps {
  users?: IUser[];
  triageUsers?: IUser[];
}

const UserActivityBar: React.FC<UserActivityBarProps> = ({
  users,
  triageUsers
}) => {
  return (
    <div className="user-activity-bar">
      <div className="box">
        <h3>Triage</h3>
        {triageUsers && triageUsers.length > 0 ? (
          triageUsers?.map(u => (
            <Avatar
              key={u.email}
              imageUrl={u.avatarUrl}
              userStatus={u.status}
              userName={u.name}
              initials={u.initials}
            />
          ))
        ) : (
          <i className="fas fa-2x fa-skull-crossbones danger" />
        )}
        <hr />
        {users?.map(u => (
          <Avatar
            key={u.email}
            imageUrl={u.avatarUrl}
            userStatus={u.status}
            userName={u.name}
            initials={u.initials}
          />
        ))}
      </div>
    </div>
  );
};

export default UserActivityBar;
