import React, { useState, useEffect } from 'react';
import DashboardService from "../../services/DashboardService";
import { IZendeskTicketCount } from '../../services/interfaces/IDashboardService';

const controller = new DashboardService({ sendAsync: fetch.bind(window) });

function KPITile() {
    const [openTickets, setOpenTickets] = useState<IZendeskTicketCount>()
    const [pullRequests, setPullRequests] = useState(0)
    useEffect(() => {
        async function getData() {
            const response = await controller.getOpenZendeskTicketCount();
            setOpenTickets(await response);
            const response2 = await controller.getPullRequestCount();
            setPullRequests(await response2);
        }
        setInterval(() => {
            getData()
        }, 300000)
        getData();
    }, [])

    return (
        <div className="tile is-parent is-6">
            <div className="tile is-child">
                <p className="title"><b>KPIs</b></p>
                <div className="content has-text-centered">
                    <div className="tile is-ancestor is-12 content-container">
                        <div className="tile is-parent is-6">
                            <div className="tile is-child box">
                                <div className="name"><b className="title">Zendesk Tickets</b></div>
                                <div style={{ display: 'flex', marginBottom: '2%', justifyContent: 'space-around' }}>
                                    <div style={{ float: "left" }}>
                                        <p style={{ fontSize: 130 }}>{openTickets?.newTickets} <p style={{ fontSize: 20, fontWeight:'bold' }}>NEW</p></p>
                                    </div>
                                    <div>
                                        <p style={{ fontSize: 130 }}>{openTickets?.pendingTickets} <p style={{ fontSize: 20, fontWeight:'bold' }}>PENDING</p></p>
                                    </div>
                                    <div style={{ float: "left" }}>
                                        <p style={{ fontSize: 130 }}>{openTickets?.openTickets} <p style={{ fontSize: 20, fontWeight:'bold' }}>OPEN</p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tile is-parent is-6">
                            <div className="tile is-child box">
                                <div className="name"><b className="title"><i>Zendesk Response Time</i></b></div>
                                <div style={{ fontSize: 130 }}>100%</div>
                            </div>
                        </div>
                        <div className="tile is-parent is-6">
                            <div className="tile is-child box">
                                <div className="name"><b className="title"><i>Current Taskrouter Tasks</i></b></div>
                                <div style={{ fontSize: 130 }}>0</div>
                            </div>
                        </div>
                        <div className="tile is-parent is-6">
                            <div className="tile is-child box">
                                <div className="name"><b className="title">Open Pull Requests</b></div>
                                <div style={{ fontSize: 130 }}>{pullRequests}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KPITile;