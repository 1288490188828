import React, { useState, useEffect, useRef, FormEvent } from "react";
import "./DashboardLogin.scss";
import DashboardService from "../../services/DashboardService";
import { generateChangeHandler } from "../../utils";
import { useDispatch } from "react-redux";
import { setDashboardLogin } from "../../redux/authReducer";

const service = new DashboardService({ sendAsync: fetch.bind(window) });
type SubmitStatus = "toSubmit" | "submitted" | "none";

const DashboardLogin: React.FC = () => {
    const componentIsMounted = useRef(true);
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>("none");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [deviceId, setDeviceId] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        return () => { componentIsMounted.current = false; }
    }, []);

    useEffect(() => {
        let timeoutId: number | undefined;

        const checkLogin = async () => {
            const result = await service.checkDashboardCredentials({ deviceId, password });
            if (result && componentIsMounted.current) {
                setSubmitStatus("none");
                dispatch(setDashboardLogin({ deviceId, password }));
            } else if (componentIsMounted.current) {
                setError("Invalid Credentials");
                setSubmitStatus("none");
                timeoutId = window.setTimeout(() => {
                    if (componentIsMounted.current) {
                        setError("");
                    }
                }, 2000);
            }
        };

        if (submitStatus === "toSubmit") {
            setSubmitStatus("submitted");
            setError("");
            checkLogin();
        }
        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        }
    }, [deviceId, password, submitStatus, dispatch])

    const submitLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (deviceId.trim() && password.trim()) {
            setSubmitStatus("toSubmit");
        }
        return false;
    };

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Login</p>
                </header>
                <section className="modal-card-body">
                    <form onSubmit={submitLogin}>
                        <fieldset disabled={submitStatus !== "none"}>
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input value={deviceId} onChange={generateChangeHandler(setDeviceId)} className="input" type="input" placeholder="Device Id" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-id-card"></i>
                                    </span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input value={password} onChange={generateChangeHandler(setPassword)} className="input" type="password" placeholder="Password" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </p>
                            </div>
                            {error ? <p className="help is-danger">{error}</p> : null}
                            {submitStatus !== "none" ? <p className="help is-info">Checking Credentials ...</p> : null}
                            <div className="field is-grouped is-grouped-right">
                                <p className="control">
                                    <button type="submit" className="button is-success">
                                        Login
                            </button>
                                </p>
                            </div>
                        </fieldset>
                    </form>
                </section>
            </div>
        </div>
    );
};

export default DashboardLogin;
