import { IHttpService } from "./interfaces/IHttpService";
import store from "../redux/store";
import { ISignalRService, IConnectionInfoExtended } from "./interfaces/ISignalRService";
import moment from "moment";

export default class SignalRService implements ISignalRService{

    private baseUrl = process.env.REACT_APP_API_BASE_URL + "signalr/";
    private httpService: IHttpService;

    private mergeAuthHeader(headers: Headers): Headers {
        const headerValue = store.getState().auth.authHeaderValue;
        if (headerValue) {
          headers.set("Authorization", headerValue);
        }
        return headers;
    }

    private async negotiateConnectionRequest(action: string): Promise<IConnectionInfoExtended>{
        const response = await this.httpService.sendAsync(
            `${this.baseUrl}${action}`, {
            headers: this.mergeAuthHeader(new Headers({}))
        });
        if (response.status !== 200) {
            return Promise.reject("Failed to negotiate connection request");
          } else {
            const res: IConnectionInfoExtended = await response.json();
            res.accessTokenExpiry = moment().add(res.ttl, "seconds");
            return res;
          }
    }

    constructor(httpService: IHttpService) {
        this.httpService = httpService;
    }

    negotiateConnectionRequestForGenericHub(): Promise<IConnectionInfoExtended>{
        return this.negotiateConnectionRequest("NegotiateConnectionRequestForGenericHub");
    };

    negotiateConnectionRequestForDevicesHub(): Promise<IConnectionInfoExtended>{
        return this.negotiateConnectionRequest("NegotiateConnectionRequestForDevicesHub");
    };
}
