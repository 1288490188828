import { User, UserManager } from "oidc-client";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { googleSessionExpired, googleSessionLoadedError, googleSessionLoadedOk } from "../../redux/authReducer";

interface AuthListenerProps {
    userManager: UserManager;
}

const AuthListener: React.FC<AuthListenerProps> = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const onUserLoaded = (user: User) => {
            if (user && !user.expired) {
                dispatch(googleSessionLoadedOk({
                    //NOTE: googles id token is the JWT and as same lifetime as their beearer access_token.
                    // therefore can use to protect our API
                    token: user.id_token,
                    expires_at: user.expires_at,
                    profile: user.profile,
                }));
                return;
            }
            dispatch(googleSessionLoadedError());
        };
        const onUserUnloaded = () => {
            dispatch(googleSessionExpired());
        };
        const onUserSignedOut = () => {
            dispatch(googleSessionExpired());
        };
        const onAccessTokenExpired = () => {
            dispatch(googleSessionExpired());
        };
        props.userManager.events.addUserLoaded(onUserLoaded);
        props.userManager.events.addUserUnloaded(onUserUnloaded);
        props.userManager.events.addUserSignedOut(onUserSignedOut);
        props.userManager.events.addAccessTokenExpired(onAccessTokenExpired);
        return () => {
            props.userManager.events.removeUserLoaded(onUserLoaded);
            props.userManager.events.removeUserUnloaded(onUserUnloaded);
            props.userManager.events.removeUserSignedOut(onUserSignedOut);
            props.userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
        };
    }, [props.userManager, dispatch]);

    return (<>{props.children}</>);
};

export default AuthListener;
