import React, { useEffect, useState } from "react";
import "./OKRTile.scss";
import DashboardService from "../../services/DashboardService";
import { IGoal, IMetric } from "../../services/interfaces/IDashboardService";



const controller = new DashboardService({ sendAsync: fetch.bind(window) });
function OKRTile() {
    const [isLoading, setLoading] = useState(true);
    const [goals, setGoals] = useState<IGoal[]>([]);
    const displayLabels = ["In Progress", "Complete", "To Do"];

    useEffect(() => {

        let isMounted = true;
        const getData = async () => {
            const res = await controller.getTrelloData();
            if (isMounted) {
                setLoading(false);
                setGoals(res);
            }
        };
        setInterval(() => {
            getData();
        },300000);
        getData();
        return () => {
            isMounted = false;
        }        
    },[]);

    return (
        <div className="tile is-parent is-6">
            <div className="tile is-child">
                <p className="title"><b>OKRs</b></p>
                <div className="content">
                    {isLoading ? (
                        <div className="loading-msg">
                            <p><b className="loading-text">Loading...</b></p>
                            <progress className="progress is-small is-danger" max="100" />
                        </div>
                    ) : !isLoading && goals.length > 0 ? (
                        <div className="tile is-ancestor is-12 content-container">
                            {goals.map((goal: IGoal, index) => (
                                <div className="tile is-parent is-6" key={index}>
                                    <div className="tile is-child box">
                                        <div className="goal-name"><b>{(goal.cards.filter((c) => (c.labels.length > 0 ? (c.labels[0].name) : ("")) === "Complete").length / goal.cards.length) * 100}% - <span className="goal-title">{goal.name}</span></b></div>
                                        <table className="table is-narrow is-fullwidth">
                                            <tbody>
                                                {goal.cards.filter((c) => displayLabels.indexOf(c.labels.length > 0 ? (c.labels[0].name) : ("")) > -1).splice(0,4).map((card: IMetric, index) => (
                                                    <tr key={index}>
                                                        <td className="metric-name">{card.name}</td>
                                                        <td className="status-cell" style={{textAlign: "right"}}><span className={card.labels.length > 0 && card.labels[0].name === "In Progress" ? ("tag is-warning is-rounded status-tag") : (card.labels[0].name === "To Do" ? "tag is-danger is-rounded status-tag" : "tag is-success is-rounded status-tag")}><b>{card.labels.length > 0 ? (card.labels[0].name) : ("")}</b></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                                <div className="error-msg">
                                    <b>Error!</b>
                                </div>
                            )}
                </div>
            </div>
        </div>
    );
};

export default OKRTile;
