import React from "react";
import "./HeaderLayout.scss";

interface HeaderLayoutProps {
  hideHeader?: boolean;
  centerItems?: JSX.Element[];
  rightItems?: JSX.Element[];
}
const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  hideHeader,
  centerItems,
  rightItems,
  children
}) => {

  return (
    <div className="header-layout">
      {hideHeader ? null : (<div className="level zing-header">
        <div className="level-left">
          <img
            src="https://brass-earwig-2836.twil.io/assets/GreyBackground.svg"
            alt="The Zing Logo"
            className="level-item"
          />
        </div>
        {centerItems?.map((i, index) => (
          <div className="level-item has-text-centered" key={index}>
            {i}
          </div>
        ))}
        <div className="level-right">
          {rightItems?.map((i, index) => (
            <div className="level-item" key={index}>
              {i}
            </div>
          ))}
        </div>
      </div>)}
      <div className="container">{children}</div>
    </div>
  );
};

export default HeaderLayout;
