import React from "react";
import { useSelector } from "../redux/rootReducer";
import { selectIsAuthenticated } from "../redux/authSelectors";
import Dashboard from "./Dashboard";
import DashboardLogin from "../components/auth/DashboardLogin";
import AuthListener from "../components/auth/AuthListener";
import { userManagerConfig } from "../auth/config";
import { UserManager } from "oidc-client";
import AuthCallback from "./AuthCallback";
import { getCallbackMode, getDisplayMode, storePath } from "../auth/pageParams";

const userManager = new UserManager(userManagerConfig);
(window as any).um = userManager;

const Router: React.FC = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const callbackMode = getCallbackMode();
    const displayMode = getDisplayMode();

    const redirectToLogin = (): JSX.Element => {
        storePath();
        userManager.signinRedirect();
        return <p>redirecting to login, please wait ...</p>
    };
    return (
        <>
            {displayMode === "dashboard" ? isAuthenticated ? <Dashboard userManager={userManager}/> : <DashboardLogin />
                :
                <AuthListener userManager={userManager}>
                    {isAuthenticated ? <Dashboard userManager={userManager}/> : callbackMode !== "none" ? <AuthCallback userManager={userManager} inBackground={callbackMode === "background"} /> : redirectToLogin()}
                </AuthListener>
            }
        </>
    );
};

export default Router;
