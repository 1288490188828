import React, { useState, useEffect } from "react";
import "./Clock.scss";

const Clock: React.FC = () => {
  function padLeft(s: number) {
    if (s < 10) {
      return "0" + s;
    } else {
      return s;
    }
  }

  function formatTime(d: Date) {
    return `${padLeft(d.getHours())}:${padLeft(d.getMinutes())}`;
  }

  function getISTTime(d: Date) {
    const currentOffset = d.getTimezoneOffset();
    return new Date(d.getTime() + (330 + currentOffset) * 60000);
  }

  const [time, setTime] = useState(new Date());
  const [inTime, setINTime] = useState(getISTTime(time));
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!timer) {
      setTimer(
        setInterval(() => {
          const d = new Date();
          setTime(d);
          setINTime(getISTTime(d));
        }, 10000)
      );
    }
  }, [timer]);

  return (
    <div className="clock">
      <div className="level">
        <span className="level-item-left">UK</span>
        <h3 className="level-item-right">{formatTime(time)}</h3>
      </div>
      <div className="level">
        <span className="level-item-left">India</span>
        <h3 className="level-item-right">{formatTime(inTime)}</h3>
      </div>
    </div>
  );
};

export default Clock;
