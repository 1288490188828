import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const md5 = require('md5');

export interface CallbackUser {
    token: string;
    expires_at: number;
    profile: any;
}

export interface AuthState {
    authHeaderValue?: string;
    userInfo?: UserInfo;
}

export interface UserInfo {
    id: string;
    name: string;
    email: string;
    imageUrl: string;
}

const initialState: AuthState = {
    authHeaderValue: undefined,
    userInfo: undefined,
};

const generateImageUrl = (email: string) => {
    return `${window.location.protocol}//www.gravatar.com/avatar/${md5(email)}`;
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        googleSessionExpired(state) {
            state.userInfo = undefined;
            state.authHeaderValue = undefined;
        },
        googleSessionLoadedError(state) {
            state.userInfo = undefined;
            state.authHeaderValue = undefined;
        },
        googleSessionLoadedOk(state, action: PayloadAction<CallbackUser>) {
            state.userInfo = {
                id: action.payload.profile.sub ? action.payload.profile.sub : "",
                name: action.payload.profile.name ? action.payload.profile.name : "",
                email: action.payload.profile.email ? action.payload.profile.email : "",
                imageUrl: generateImageUrl(action.payload.profile.email ? action.payload.profile.email : ""),
            };
            state.authHeaderValue = `Bearer ${action.payload.token}`;
        },
        setDashboardLogin(state, action: PayloadAction<{ deviceId: string, password: string }>) {
            state.userInfo = {
                id: action.payload.deviceId,
                email: "help@zing.dev",
                name: action.payload.deviceId,
                imageUrl: generateImageUrl("help@zing.dev"),
            };

            state.authHeaderValue = `DeviceBasic ${window.btoa(action.payload.deviceId.trim() + ":" + action.payload.password.trim())}`
        },
        clearLogin(state) {
            state.userInfo = undefined;
            state.authHeaderValue = undefined;
        }
    },
});

export const { googleSessionExpired, googleSessionLoadedError, googleSessionLoadedOk, clearLogin, setDashboardLogin } = authSlice.actions;

export default authSlice.reducer;
