export interface ILabel {
  id: string;
  idBoard: string;
  name: string;
  colour: string;
};

export interface IMetric {
  id: string;
  name: string;
  labels: ILabel[];
  idList: string;
}

export interface IGoal {
  id: string;
  name: string;
  cards: IMetric[];
}

export const UserStatuses = {
  offline: "Offline",
  available: "Available",
  busy: "Busy"
};

export interface IUser {
  name: string;
  initials: string;
  email: string;
  avatarUrl?: string;
  status: keyof typeof UserStatuses;
  isOnTriage: boolean;
}

export interface IReleaseInfo {
  key: string;
  closed: Number;
  open: Number;
  date: string;
  name: string;
}

export interface IEventsInfo {
  summary: string;
  colorId: string;
  start: string;
  end: string;
}
export interface IZendeskTicketCount{
  newTickets: Number;
  pendingTickets: Number;
  openTickets: Number;
}

export interface IDashboardService {
  getUsers: () => Promise<IUser[]>;
  checkDashboardCredentials: (data: { deviceId: string, password: string }) => Promise<boolean>;
  getTrelloData: () => Promise<IGoal[]>;
}
