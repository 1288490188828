import React, { useState, useEffect } from "react";
import HeaderLayout from "../components/HeaderLayout";
import SidebarLayout from "../components/SidebarLayout";
import UserActivityBar from "../components/UserActivityBar";
import OKRTile from "../components/tiles/OKRTile";
import ReleasesTile from '../components/tiles/ReleasesTile';
import Profile from '../components/Profile';
import { IDashboardService, IUser } from "../services/interfaces/IDashboardService";
import DashboardService from "../services/DashboardService";
import { getDisplayMode } from "../auth/pageParams";
import Clock from "../components/Clock";
import { UserManager } from "oidc-client";
import SignalRService from "../services/SignalRService";
import useSignalRClient from "../hooks/useSignalRClient";
import './Dashboard.scss';
import KeyEvents from "../components/tiles/KeyEventsTile";
import KPITile from "../components/tiles/KPITile";
export interface DashboardProps { userManager: UserManager }
const service = new SignalRService({ sendAsync: fetch.bind(window) });
let intervalCounter = 0;
let interval = false;

const Dashboard: React.FC<DashboardProps> = (props) => {
  const [dashboardService, setDashboardService] = useState<IDashboardService>();
  const [loadUsers, setLoadUsers] = useState(true);
  const [userList, setUserList] = useState<IUser[]>([]);
  const [showAvatar, setShowAvatar] = useState(false);
  const [showCall, setShowCall] = useState('none');
  let [bgColor, setBgColor] = useState('');
  const getConnectionInfo = () => {
      return service.negotiateConnectionRequestForDevicesHub();
  }
  const alertDashboard = (callState: string) => {
      console.log(interval);
      if(callState[0] === "1"){
        setShowCall('none');
        setBgColor('#2b3040');
        intervalCounter = 2;
      }
      if(callState[0] === "0" && intervalCounter === 2){
        intervalCounter = 0;
      }
      else if(callState[0] === "0" && !interval){        
        setInterval(() => {
          if(intervalCounter === 0){
            setShowCall('block');
            setBgColor('#2b3040')
            intervalCounter = 1;
          }
          else if(intervalCounter === 1){
            setShowCall('block');
            setBgColor('#de007e')
            intervalCounter = 0;
          }
        }, 1000);
        interval = true;
      }
  };
  useSignalRClient(
      getConnectionInfo,
      {
          "alertDashboard": alertDashboard,
      }
  );
  useEffect(() => {
    if (!dashboardService) {
      setDashboardService(
        new DashboardService({ sendAsync: fetch.bind(window) })
      );
    }

    if (loadUsers && dashboardService) {
      dashboardService
        .getUsers()
        .then(res => setUserList(res))
        .catch(err => console.log(err));

      setLoadUsers(false);
    }

    setInterval(() => {
      dashboardService?.getUsers()
        .then(res => setUserList(res))
        .catch(err => console.log(err))
    }, 300000);
    setShowAvatar(getDisplayMode() === 'website')
  }, [loadUsers, dashboardService]);

  return (
      <HeaderLayout
        hideHeader={getDisplayMode() === "flex"}
        centerItems={[<div>INQUISITIVE - RELENTLESS - ENERGETIC</div>]}
        rightItems={[<Clock />, showAvatar ? <Profile userManager={props.userManager} /> : <></>]}
      >
        <SidebarLayout
          sideItem={
            <UserActivityBar
              users={userList.filter(x => !x.isOnTriage)}
              triageUsers={userList.filter(x => x.isOnTriage)}
            />
          }
        >
          <OKRTile />
          <ReleasesTile />
          <i style={{fontSize: 500, position: 'absolute', top: window.innerHeight * 0.2, color: bgColor, display: showCall, }} className="fas fa-phone-volume call_icon"></i>
          <KeyEvents />
          <KPITile />
        </SidebarLayout>
      </HeaderLayout>
  );
};

export default Dashboard;
