import { UserManagerSettings } from "oidc-client";

const OIDC_BASE_PATH: string = "/oidc/";
export const CALLBACK_PATH: string = `${OIDC_BASE_PATH}callback`;
export const SILENT_RENEW_PATH: string = `${OIDC_BASE_PATH}silent_renew`;

const generateUrl = (path: string): string => {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}${path}`;
};

export const userManagerConfig: UserManagerSettings = {
    accessTokenExpiringNotificationTime: 300,
    authority: "https://accounts.google.com",
    automaticSilentRenew: true,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    extraQueryParams: { "hd": "zing.dev" },
    filterProtocolClaims: true,
    loadUserInfo: false,
    redirect_uri: generateUrl(CALLBACK_PATH),
    response_type: "id_token token",
    scope: "openid email profile",
    silent_redirect_uri: generateUrl(SILENT_RENEW_PATH),
};
