import React, {useState, useEffect} from 'react';
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import DashboardService from "../../services/DashboardService";
import "react-vertical-timeline-component/style.min.css";
import { IEventsInfo } from '../../services/interfaces/IDashboardService';

const controller = new DashboardService({ sendAsync: fetch.bind(window) });

function KeyEvents() {
    const [events, setEvents] = useState<IEventsInfo[]>([]);
    useEffect(() => {
      async function getData() {
        const response = await controller.getEvents();
        setEvents(await response.splice(0,3));
      }
      setInterval(() => {
        getData()
      },300000)
      getData();
    },[])
    return(
        <div className="tile is-parent is-6">
            <div className="tile is-child">
                <p className="title"><b>Key Events</b></p>
                <div className="content">
                  <VerticalTimeline>
                      {events.map((event: IEventsInfo, i) => {
                        
                        const start = event.start.split(' ')[0].split('/')
                        const end = event.end.split(' ')[0].split('/')
                        let startDate = event.start;
                        let endDate = event.end;
                        if(event.start.length > 10){
                          startDate = `${start[1]}/${start[0]}/${start[2]}`
                          endDate = `${end[1]}/${end[0]}/${end[2]}`
                          startDate = startDate.replace(/\b(\d{1})\b/g, '0$1');
                          endDate = endDate.replace(/\b(\d{1})\b/g, '0$1');
                        }

                        return(
                          <VerticalTimelineElement
                          className="vertical-timeline-element"
                          iconStyle={{ background: "#de007e"}}
                          date={startDate === endDate ? startDate : `${startDate} - ${endDate}`}
                          key={i}
                        >
                          <h4
                            className="vertical-timeline-element-title"
                            dangerouslySetInnerHTML={{ __html: event.summary }}
                          />
                          </VerticalTimelineElement>
                        );
                      })}
                    </VerticalTimeline>
                </div>
            </div>
        </div>
    )
}

export default KeyEvents;