import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";

const rootReducer = combineReducers({
    auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// export override for use selector that is correctly typed
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default rootReducer;
