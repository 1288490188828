import { IDashboardService, IUser, IGoal, IReleaseInfo, IEventsInfo, IZendeskTicketCount } from "./interfaces/IDashboardService";//
import { IHttpService } from "./interfaces/IHttpService";
import store from "../redux/store";

export default class DashboardService implements IDashboardService {
  private baseUrl = process.env.REACT_APP_API_BASE_URL + "dashboard/";
  private httpService: IHttpService;

  private mergeAuthHeader(headers: Headers): Headers {
    const headerValue = store.getState().auth.authHeaderValue;
    if (headerValue) {
      headers.set("Authorization", headerValue);
    }
    return headers;
  }

  constructor(httpService: IHttpService) {
    this.httpService = httpService;
  }

  async getUsers(): Promise<IUser[]> {
    const response = await this.httpService.sendAsync(
      `${this.baseUrl}getuserslist`, {
      headers: this.mergeAuthHeader(new Headers({}))
    });

    if (response.status !== 200) {
      return Promise.reject("Failed to fetch all users");
    } else {
      return await response.json();
    }
  }

  async getTrelloData(): Promise<IGoal[]> {
    const response = await this.httpService.sendAsync(
      `${this.baseUrl}gettrellodata`, {
      headers: this.mergeAuthHeader(new Headers({}))
    }
    );

    if (response.status !== 200) {
      return Promise.reject("Failed to fetch lists/cards");
    } else {
      return await response.json();
    }
  }

  async checkDashboardCredentials(data: { deviceId: string, password: string }): Promise<boolean> {
    const response = await this.httpService.sendAsync(`${this.baseUrl}CheckCredentials`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    });
    return response.status === 200;
    // return new Promise<boolean>((resolve, reject) => {
    //   window.setTimeout(() => {
    //     resolve(true);
    //   }, 2000);
    // });
  }

  async getReleases(): Promise<IReleaseInfo[]>{
    const response = await this.httpService.sendAsync(`${this.baseUrl}GetReleases`, {
      headers: this.mergeAuthHeader(new Headers({}))
    });

    return await response.json();
  }

  async getEvents(): Promise<IEventsInfo[]>{
    const response = await this.httpService.sendAsync(`${this.baseUrl}GetEvents`, {
      headers: this.mergeAuthHeader(new Headers({}))
    });
    return await response.json();
  }
  async getOpenZendeskTicketCount():Promise<IZendeskTicketCount>{
    const response = await this.httpService.sendAsync(`${this.baseUrl}CountOpenTickets`, {
      headers: this.mergeAuthHeader(new Headers({}))
    });
    return await response.json();
  }
  async getPullRequestCount(){
    const response = await this.httpService.sendAsync(`${this.baseUrl}GetPullRequestCount`, {
      headers: this.mergeAuthHeader(new Headers({}))
    });
    return await response.json();
  }
}
