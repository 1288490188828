import { UserManager } from "oidc-client";
import React, { useEffect, useState } from "react";
import { setPathAfterLoginCallback } from "../auth/pageParams";

interface IAuthCallbackProps {
    inBackground: boolean;
    userManager: UserManager;
}

const AuthCallback: React.FC<IAuthCallbackProps> = (props) => {
    type CallbackStatus = "done" | "error" | "pending";

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showRetry, setShowRetry] = useState<boolean>(false);
    const [status, setStatus] = useState<CallbackStatus>("pending");

    useEffect(() => {
        let inCleanup = false;
        const successCallbackHandler = (): void => {
            if (!inCleanup) {
                setErrorMessage("");
                setStatus("done");
            }
            if (!props.inBackground) {
                setPathAfterLoginCallback();
            }
        };
        const errorCallbackHandler = (error: Error): void => {
            if (!inCleanup) {
                setErrorMessage(error && error.message ? error.message : "something went wrong");
                setShowRetry(!props.inBackground);
                setStatus("error");
            }
        };
        const handleCallback = async () => {
            try {
                if (props.inBackground) {
                    await props.userManager.signinSilentCallback();
                } else {
                    await props.userManager.signinRedirectCallback();
                }
                successCallbackHandler();
            } catch (err) {
                errorCallbackHandler(err);
            }
        };
        handleCallback();
        return () => {
            inCleanup = true;
        }
    }, [props.inBackground, props.userManager]);

    const retryClickHandler = (): void => {
        setErrorMessage("");
        window.location.href = "/";
    };

    return (
        <div>
            {status === "error"
                ? (
                    <><p>{`Login Error: ${errorMessage}`}</p>
                        {showRetry
                            ? <button className="button is-primary" onClick={retryClickHandler}>Retry</button>
                            : null}
                    </>
                )
                : status === "done"
                    ? "login refresh successful"
                    : "processing login, please wait ..."}
        </div>
    );
};

export default AuthCallback;
